import React, { memo,  useState } from "react";

const Question = ({ qn, setAnswer, answer, data }) => {
  const [selectedOption, setSelectedOption] = useState("");


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setAnswer({ ...answer, [`ans${qn}`]: event.target.value });
  };
  return (
    <div id={`question${data.qn}`} className="question-container">
      <p>
        {data.qn}. {data.question}
      </p>
      <div className="options">
        <label className={`option ${selectedOption === "1" ? "selected" : ""}`}>
          <input
            type="radio"
            value="1"
            checked={selectedOption === "1"}
            onChange={handleOptionChange}
          />
          {data.options[0]}
        </label>
        <label className={`option ${selectedOption === "2" ? "selected" : ""}`}>
          <input
            type="radio"
            value="2"
            checked={selectedOption === "2"}
            onChange={handleOptionChange}
          />
          {data.options[1]}
        </label>
        <label className={`option ${selectedOption === "3" ? "selected" : ""}`}>
          <input
            type="radio"
            value="3"
            checked={selectedOption === "3"}
            onChange={handleOptionChange}
          />
          {data.options[2]}
        </label>
        <label className={`option ${selectedOption === "4" ? "selected" : ""}`}>
          <input
            type="radio"
            value="4"
            checked={selectedOption === "4"}
            onChange={handleOptionChange}
          />
          {data.options[3]}
        </label>
      </div>
    </div>
  );
};

export default memo(Question);
