import React from 'react'

const Submitted = () => {
  return (
    <div style={{height:"75vh",width:"90%",margin:"auto",textAlign:"center",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",fontSize:"1.5rem",color:"#bbb"}}>
        <p>Your feedback has been submitted sucessfully!</p>
        <p>Thank You!</p>

    </div>
  )
}

export default Submitted