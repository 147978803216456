module.exports.questions = [
  {
    qn: 1,
    question: "Mindfulness in Water Usage ",
    options: [
      "Washing Machine Mindfulness",
      "Running water while brushing / washing utensils",
      "Rainwater harvesting",
      "Using half flush",
    ]
  },
  {
    qn: 2,
    question: "Mindfulness in Electricity Usage",
    options: [
      "AC not lower than 24 degrees",
      "LED/Smart systems installed",
      "Standby Electronics",
      "Regular maintenance",
    ]
  },
  {
    qn: 3,
    question: "Mindfulness in Plastic Usage",
    options: [
      "Doing away with plastic bottles/utensils/containers",
      "Not tearing away the corners of plastic packaging",
      "Not using plastic covers, folders in the office",
      "Carrying own recyclable bags to shopping malls",
    ]
  },
  {
    qn: 4,
    question: "Mindfulness in Eating Habits",
    options: [
      "Growing your own food in some way – kitchen garden, hydroponics",
      "Checking the ingredients of packaged food",
      "Preference for fresh cooked food",
      "Reusing leftovers or efficiently giving them away",
    ]
  },
  {
    qn: 5,
    question: "Mindfulness in Paper Usage",
    options: [
      "Paperless office and homes",
      "If not paperless, shredding paper and being connected to a paper recycling initiative",
      "No printed business cards",
      "No printed marketing collaterals",
    ]
  },
  {
    qn: 6,
    question: "Mindfulness in using Green Products",
    options: [
      "Using Renewable Energy Sources in Home, Office, Logistics",
      "Not using bubble wraps",
      "Not using styrofoam containers",
      "Deleting emails and saving server space",
    ]
  }
];
