import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "./components/Main";
import Submitted from "./components/Submitted";
import logo from "../src/components/logo.png";

function App() {
  return (
    <div className="app">
      <div className="nav-bar">
        <div className="logo">
          <img src={logo} alt="GreenEnco" />
        </div>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/submitted" element={<Submitted />} />
        </Routes>
        <div className="footer">
        <p>
          ©{" "}<Link target="_blank" to="https://greenenco.co.uk">
            greenenco.co.uk.
          </Link> All Rights Reserved.
        </p>
      </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
