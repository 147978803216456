import React, { useState } from "react";
import "./main.css";
import Question from "./Question";
import { useNavigate } from "react-router-dom";
import { questions } from "./questions";
import axios from "axios";

const Main = () => {
  const [answer, setAnswer] = useState({
    name: "",
    ans1: "",
    ans2: "",
    ans3: "",
    ans4: "",
    ans5: "",
    ans6: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    for (let i in answer) {
      if (!answer[i] && i !== "name") {
        setError(
          `Provide a answer for question number ${i?.replace("ans", "")}`
        );

        setTimeout(() => {
          setError(null);
        }, 5000);
        return;
      }
    }
    try {
      setLoading(true);
      const { data } = await axios.post(
        "https://feedbackform.greenenco.in/api/v1/storeFeedbackResult",
        { data: answer }
      );
      if (data?.sucess) {
        navigate("/submitted");
      }
    } catch (error) {
      setError("Submit faild, Please contact the owner");
      setTimeout(() => {
        setError(null);
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ul className="colorlib-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      {error && (
        <div className="error-container">
          <p>
            <span>{error}</span>
            <span onClick={() => setError(false)}>X</span>
          </p>
        </div>
      )}

      <div className="quiz-container">
        <h3>Audience Mindfulness Questionnaires</h3>
        <form className="options-list" onSubmit={(e) => onSubmitHandler(e)}>
          {questions?.map((e, index) => {
            return (
              <Question
                data={e}
                key={index}
                answer={answer}
                setAnswer={setAnswer}
                qn={index + 1}
              />
            );
          })}

          <div className="option">
            <label htmlFor="name">Enter FullName</label>
            <input
              value={answer.name}
              onChange={(e) => setAnswer({ ...answer, name: e.target.value })}
              type="text"
              id="name"
              placeholder="Optional"
            />
          </div>

          <div className="submit">
            {
              loading && <span></span>
            }
           
            <button
              disabled={error ? true : false}
              style={{ cursor: !error ? "pointer" : "default" }}
              type="submit"
            >
              {loading ? "Submitting . . ." : "Submit"} {" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Main;
